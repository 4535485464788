<template>
  <div class="about_us">
    <div class="about_banner"></div>
    <h2 class="h2 brief">渠道合作</h2>
    <div class="about_context">
      <div class="context_item">
        <img src="~@/assets/img/pc/about/about_us_item01.jpg" />
        <div class="text">
          经过长期的技术和市场积累，湖南优享云通信技术有限公司（下称：优享云通信）的产品线得到全面完善，企业与品牌的知名度日渐增加，“创新促进发展，合作寻求共赢”已经成为当前优享云通信公司提高市场覆盖率和占有率、加强企业核心竞争力的重要战略之一,
          优享云通信公司将本着“平等合作、双向互利”的合作原则吸纳及培养一批具备行业及区域实力的产品代理商、系统集成商等，作为优享云通信公司长期稳定发展的战略合作伙伴。
        </div>
        <p></p>
      </div>
      <div class="context_item background">
        <img src="~@/assets/img/pc/about/about_us_item02.jpg" />
        <p></p>
        <div class="text">
          优享云通信公司希望与合作伙伴建立一种相互理解、尊重和支持的长远合作关系。并注重合作伙伴出色的管理模式、良好的服务意识、独特的行业见解及积极的合作意愿等。优享云通信公司将根据合作伙伴的实际情况与发展需要，共同探讨符合双方利益的合作方案，以求尽量发挥各自的优势，令双方共同发展，创造共赢局面。
        </div>
      </div>
      <div class="context_item">
        <img src="~@/assets/img/pc/about/about_us_item03.jpg" />
        <div class="text">
          如果您公司看好无线网络准入市场，期望在市场机遇中迅速崛起，请赶紧加入到优享云通信的渠道团队中来，让更多的渠道渠道合作可以通过销售优享云通信的产品及技术服务而从中获益，让我们一起携手共创信息安全美好未来！
        </div>
        <p></p>
      </div>
    </div>
    <h2 class="h2 us">我们的优势</h2>
    <img src="~@/assets/img/pc/about/about_us_item04.jpg" class="us_img" />
    <FootMain :isBanner="isBanner"></FootMain>
  </div>
</template>

<script>
import FootMain from '@/components/m/foot_main/index.vue'
export default {
  components: { FootMain },
  data() {
    return {
      isBanner: false
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.about_nav {
  height: 3.947368rem;
  width: 100%;
  background-color: #7435f2;
}
.about_banner {
  height: 12.236842rem;
  width: 100%;
  background-image: url('~@/assets/img/pc/about/about_banner.jpg');
  background-size: auto 100%;
}
.brief {
  margin-top: 1.315789rem;
  margin-bottom: 2.368421rem;
}
.about_context {
  // width: 43.815789rem;
  // margin: 0 auto;
  .context_item {
    width: 100%;
    // margin: 0 auto;
    padding: 1.276595rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      width: 0.789473rem;
    }
    img {
      max-width: 13.421052rem;
      max-height: 8.947368rem;
      margin-bottom: 0.851063rem;
    }
    .text {
      color: #666;
      max-width: 80%;
      font-size: 1.191489rem;
      line-height: 2.2rem;
      text-indent: 2em;
    }
  }
}
.us {
  margin: 2.368421rem 0;
}
.us_img {
  width: 100%;
  height: 16.315789rem;
}
.background {
  background: #f6f6f6;
}
</style>
<style scoped lang="scss">
.h2 {
  font-size: 1.315789rem;
  color: #333;
  text-align: center;
  position: relative;
  &::after {
    display: block;
    content: '';
    height: 0.368421rem;
    width: 3.815789rem;
    background-color: #06f410;
    border-radius: 0.18421rem;
    position: absolute;
    bottom: -0.657894rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
